.main {
    width: 100%;
}
.main> div:first-child {
    position: absolute;
    width: 48px;
    height: 48px;
    left: 10px;
    top: 10px;
    border:1px solid #12909B;
    box-sizing: border-box;
    border-radius: 40px;

    background : white;
    

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.25px;
    color: #12909B;
    z-index: 1;
}
.slickSlide div {
  outline: none;
}


.item {
    margin: auto;
    max-width: 720px;
    min-width: 280px;
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 217px);
    max-height: 100%;
    min-height: 327px;
    display: block;
    object-fit: contain;
}

.footer {
    margin: 0 auto;
    bottom: 0px;
    height: 217px;
    width: 100%;
    position: fixed;
    text-align: center;
    background-color: white;
}

.footer>div:nth-child(2) {
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 53px;
    background-color: #E9E9E9;
}

.footerText>div:first-child {
    position: absolute;
    top: 24px;
    left: 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.25px;
    text-align: left;
    text-decoration-line: underline;
    color: #12909B;
}

.footerText>div:nth-child(2) {
    position: absolute;
    left: 24px;
    top: 49px;
    font-size: 19px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #121212;
}

.footerText>div:nth-child(3) {
    position: absolute;
    width: calc(100% - 48px);
    height: 57px;
    left: 24px;
    top: 82px;

    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    letter-spacing: 0.25px;

    /* White/text/secondary */

    color: #666666;
}