@font-face {
  font-family: Apple SD Gothic Neo;
  src: url(./fonts/apple-sd-gothic-neo-regular.woff) format('woff');
  font-style: normal;
}

html {
  height: 100%;
  background-color: #f1f4f7;
}

body {
  font: 14px "Apple SD Gothic Neo", Futura, sans-serif;
  margin: 0px;
  width: 100%;
  height: 100%;
}

.header {
  margin: 0 auto;
  top: 0px;
  height: 200px;
  width: 100%;
  /* border 때문에 화면이 넘어가니 추후 border를 지울때 100%로 변경 */
  text-align: center;
}


.footer {
  background-color: #2B2B2B;
  margin: 0 auto;
  bottom: 0px;
  height: 60px;
  width: 100%;
  position: fixed;
  text-align: center;
}
.footer > div:first-child {
  background : url(../images/icon/ic_labopen.png);
  position: absolute;
  left: 24px;
  bottom: 14px;
  width: 32px;
  height: 32px;
}
.footer > div:nth-child(2) {
  position: absolute;
  left: 64px;
  top: 13.5px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #FFFFFF;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 1px 0px;
}
.footer > div:nth-child(3) {
  position: absolute;
  left: 64px;
  top: 30.5px;
  
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;

  display: flex;
  align-items: center;
  color: #919191;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 1px 0px;
}
.footer > a:last-child > div {
  background : url(../images/icon/ic_link.png);
  position: absolute;
  right: 16px;
  bottom: 8px;
  width: 44px;
  height: 44px;
}
@media (hover: hover) and (pointer: fine) {
  .footer > div:last-child {
    cursor: pointer;
  }
}