.header {
    background: url(../images/background/sub_title_bg.png);
    height: 122px;
    position: relative;
}

.header>div:first-child {
    background: url(../images/icon/ic_navi_prev.png);
    top: 93px !important;
    left: 30px !important;
    transform: translate(-50%, -50%);
}

.header>div:nth-child(2) {
    position: absolute;
    left: 60px;
    top: 78.5px;

    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: #121212;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 0px;
}

.checkManual {
    position: relative;
    width: 100%;
    height: 120px;
}

.checkManual>div {
    position: absolute;
    height: 88px;
    width: calc(100% - 32px);
    border-radius: 10px;
    overflow: hidden;
    left: 16px;
    top: 23px;
}
.rect1 {
    position: absolute;
    width: 343px;
    height: 88px;

    background: #6094E3;
    border-radius: 10px;
}
.rect2 {
    position: absolute;
    left: -11px;
    top: 23px;
    /* border-radius: 10px; */
    border-top: 72px solid transparent;
    border-left: 364.5px solid rgba(108, 122, 245, 0.6);
}
.rect3 {
    position: absolute;
    left: 183px;
    top: -25px;

    width: calc(100% - 150px);
    height: 120px;
    transform: skew(20deg);
    background: #689FDD;
}
.rect4 {
    position: absolute;
    background: url(../images/background/sub_banner.png);
    width: 144px;
    height: 80px;
    right: 75.5px;
    top: 49px;
    transform: translate(50%, -50%);
}
.checkManual>div>div:nth-child(5) {
    position: absolute;
    left: 23px;
    top: 24px;

    font-weight: bold;
    font-size: 17px;
    line-height: 19px;
    text-align: left;
    letter-spacing: 0.25px;

    display: flex;
    align-items: center;

    color: #FFFFFF;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 5px 0px;
}
.checkManual>div>div:nth-child(6) {
    position: absolute;
    left: 23px;
    top: 48px;

    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #DBDEFF;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 5px 0px;
}

.main {
    position: absolute;
    margin: 0 auto;
    flex-direction: row;
    /* text-align: center; */
    width: 100%;
    height: calc(100% - 122px - 60px - 1px);
}

.buttonArea {
    position: absolute;
    width: 100%;
    height: calc(100% - 120px);
    overflow-y: auto;
}

.buttonWrap {
    position: relative;
    left: 16px;
    top: 17px;
    width: calc(100% - 32px);
    background: #FFFFFF;
    /* White/icon,line/grey 100 */

    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;
}