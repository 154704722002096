.slick-dots {
    bottom : 25px;
}
#slick-dots {
    bottom: 25px;
}
#slick-dots > li:not(.slick-active) > button:hover:before {
    opacity : .25;
}
/* #test > .slick-active > button:before {
    opacity : .25;
} */
/* .slick-dotsaa li.slick-active button */
/* @media (hover: none) { */
/* @media (pointer: coarse) {
    .slickSlide > ul > li:not(var(--except)) > button:hover:before {
        pointer-events: none !important;
    }
} */