.icon {
    width: 44px;
    height: 44px;
    bottom : 4px;
    position: absolute;
}
@media (hover: hover) and (pointer: fine) {
    .icon {
        cursor: pointer;
    }
}

.home {
    background: url(../images/icon/ic_tab_home_nor.png);
    margin: 0 auto;
    /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
}

.home:active {
    background: url(../images/icon/ic_tab_home_press.png);
}
@media (hover: hover) and (pointer: fine) {
    .home:hover {
        background: url(../images/icon/ic_tab_home_press.png);
    }
}

.home-dis {
    background: url(../images/icon/ic_tab_home_dis.png);
    cursor: auto !important;
}


.next {
    background: url(../images/icon/ic_tab_next_nor.png);
    right: 11px;
}

.next:active {
    background: url(../images/icon/ic_tab_next_press.png);
}
@media (hover: hover) and (pointer: fine) {
    .next:hover {
        background: url(../images/icon/ic_tab_next_press.png);
    }
}

.next-dis {
    background: url(../images/icon/ic_tab_next_dis.png);
    right: 11px;
    cursor: auto !important;
}


.prev {
    background: url(../images/icon/ic_tab_prev_nor.png);
    left: 11px;
}

.prev:active {
    background: url(../images/icon/ic_tab_prev_press.png);
}
@media (hover: hover) and (pointer: fine) {
    .prev:hover {
        background: url(../images/icon/ic_tab_prev_press.png);
    }
}

.prev-dis {
    background: url(../images/icon/ic_tab_prev_dis.png);
    left: 10.5px;
    cursor: auto !important;
}