.header {
    background: url(../images/background/main_title_bg.png);
    height: 320px;
}

.header>div:first-child {
    position: absolute;
    left: 32px;
    top: 120px;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 35px;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
}

.header>div:nth-child(2) {
    position: absolute;
    left: 32px;
    top: 155px;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: #121212;
}

.header>div:nth-child(3) {
    position: absolute;
    left: 32px;
    top: 206px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: #666666;
}

.main {
    position: absolute;
    margin: 0 auto;
    flex-direction: row;
    /* text-align: center; */
    width: 100%;
    top : 305px;
    height: calc(100% - 305px - 60px);
    overflow-y: auto;
}

.buttonWrap {
    position: absolute;
    width: calc(100% - 17px);
    left: 8.5px
}