.mainButton {
    position: relative;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin: 0 auto;
    width: 100%;
    height: 72px;
    vertical-align: middle;
    margin-bottom: 12px;
    justify-content: center;
    align-items: center;
}

.mainButton > div:first-child {
    position: absolute;
    left: 24px;
    top: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #121212;
}

.mainButton > div:nth-child(2) {
    position: absolute;
    left: 24px;
    top: 40px;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
}

.mainButton > div:nth-child(3) {
    position: absolute;
    right: 52px;
    top: 28px;
    font-size: 14px;
    line-height: 16px;
    text-align: right;

    color: #AAAAAA;
}

.mainButton > div:last-child {
    position: absolute;
    background: url(../images/icon/ic_tab_next_nor.png);
    width: 44px;
    height: 44px;
    right: 8px;
    top: 14px;
}
.mainButton:active {
    background-color: #159FAC !important;
}
.mainButton:active > div {
    color: #FFFFFF !important;
}
.mainButton:active > div:last-child {
    background: url(../images/icon/ic_tab_next_dis.png);
}

@media (hover: hover) and (pointer: fine) {
    .mainButton {
        cursor: pointer;
    }
    .mainButton:hover {
        background-color: #159FAC !important;
    }
    .mainButton:hover > div {
        color: #FFFFFF !important;
    }
    .mainButton:hover > div:last-child {
        background: url(../images/icon/ic_tab_next_dis.png);
    }
}
.subButton {
    position: relative;
    margin: 0 0;
    min-width: 100%;
    max-width: 700px;
    line-height: 50px;
    height: 60px;
    /* text-align: center; */
    vertical-align: middle;
    border-top: 1px solid #E9E9E9;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
}

.subButton > div:first-child {
    position: absolute;
    left: 24px;
    top: 19px;
    /* filter: opacity(0.5) drop-shadow(0 0 0 red); */

    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    color: #121212;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 2px 0px;
}

.subButton > div:last-child {
    position: absolute;
    background: url(../images/icon/ic_tab_next_nor.png);
    right: 12.5px;
    top: 7.5px;
    width: 44px;
    height: 44px;
}

.subButton:active > div:first-child {
    color: #159FAC !important;
}

.subButton:active > div:last-child {
    background: url(../images/icon/ic_tab_next_press.png);
}

@media (hover: hover) and (pointer: fine) {
    .subButton {
        cursor: pointer;
    }
    .subButton:hover > div:first-child {
        color: #159FAC !important;
    }
    
    .subButton:hover > div:last-child {
        background: url(../images/icon/ic_tab_next_press.png);
    }
}